<template>
  <!-- <div class="overlay-alertpop"></div> -->
  <!-- <div class="overlaying" @click="cancel()"></div> -->
  <div
    class="flex-column d-flex align-items-start justify-content-center alertpop bg-white p-5 rounded-3 shadow mx-3"
    :class="type && device == 'mobile' ? 'alert-mobile' : ''"
    :style="style"
    v-if="blnLoading == false"
  >
    <div
      class="d-flex align-items-start"
      :class="classAlertbox"
      v-if="acorgSub != true"
    >
      <component
        :is="artIcon"
        class="me-3 iconalert"
        :class="classIcon"
        width="40"
        height="40"
        :style="device == 'mobile' ? 'width:50px !important;' : ''"
      ></component>
      <!-- <span class="fnt-regular fnt-h6">{{ altMsgBox }}</span> -->
      <div>
        <span
          class="fnt-regular fnt-h6"
          :class="device == 'mobile' ? 'fnt-subtitle' : ''"
          >{{ altMsgBox }}</span
        >
        <div class="fnt-body fnt-regular">
          <span>{{ sub }}</span>
        </div>
      </div>
    </div>

    <!-- Sub-Academics เลือก status ของ Academics -->
    <div
      v-if="acorgSub == true"
      class="d-flex align-items-center justify-content-end"
    >
      <div>
        <div class="d-flex">
          <component
            :is="artIcon"
            class="me-lg-3 me-sm-1 iconalert mt-2"
            :class="classIcon"
            width="40"
            height="40"
          ></component>
          <div class="d-flex flex-column mb-3">
            <span class="title-sub-academics fnt-regular fnt-title">{{
              subtitleLine1
            }}</span>
            <span class="title-sub-academics fnt-regular fnt-title">{{
              subtitleLine2
            }}</span>
          </div>
        </div>
        <div class="d-flex">
          <component
            :is="artIcon"
            class="me-lg-3 me-sm-1 iconalert opacity-0"
            :class="classIcon"
            width="40"
            height="40"
          ></component>
          <div>
            <div class="form-check">
              <input
                class="form-check-input"
                name="datatype"
                type="radio"
                @change="selectDataType(3)"
                id="radio1"
                ref="radio1"
              />
              <label class="form-check-label">
                {{ $t("61") }}
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                name="datatype"
                type="radio"
                @change="selectDataType(2)"
                id="radio2"
                ref="radio2"
              />
              <label class="form-check-label">
                {{ $t("170") }}
              </label>
            </div>
            <!-- <Radio
              :name="$t('61')"
              inputname="datatype"
              @change="selectDataType(3)"
              id="radio1"
            />
            <Radio
              :name="$t('170')"
              inputname="datatype"
              @change="selectDataType(2)"
              id="radio2"
              ref="radio2"
            /> -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-start w-100"
      v-if="showpass == true"
    >
      <component
        :is="artIcon"
        class="me-lg-3 me-sm-1 iconalert opacity-0"
        :class="classIcon"
        width="40"
        height="40"
      ></component>
      <InputPassword
        :label="$t('344')"
        class="w-100 mt-2"
        classIcon="d-none"
        :text="
          password != '' && password != undefined && password != null
            ? 'move pass-pop'
            : 'pass-pop'
        "
        size="small"
        name="passwordPin"
        :helper="Helper"
        classInput="ps-3"
        :blnShowMSG="true"
        :blnError="blnError == true"
        :error="errorMsg"
        :value="password"
        @input="
          (event) => {
            password = event.target.value;
            blnShowMSG = false;
          }
        "
      />
      <!-- ใช้ blnError เพื่อเปิด Error message -->
    </div>

    <div class="d-flex justify-content-end mt-lg-3 mt-sm-2 w-100">
      <Button
        aria-label="Close"
        :name="strButtonSubmit == '' ? $t('36') : $t(strButtonSubmit)"
        @click="deleted()"
        class="btn-fix me-2"
        :class="classOkBtn"
        v-if="btnpop == true"
      />
      <Button
        aria-label="Close"
        :name="strButtonSubmit == '' ? $t('214') : $t(strButtonSubmit)"
        @click="yesquit()"
        class="btn-fix me-2"
        :class="classOkBtn"
        v-if="btnpop == false"
      />
      <Button
        aria-label="Close"
        :name="strButtonCancel == '' ? $t('215') : $t(strButtonCancel)"
        @click="cancel()"
        class="btn-fix btn-cancel"
        :class="classCancelBtn"
        v-if="Cancel == true"
      />
    </div>
  </div>
  <div
    class="flex-column d-flex align-items-start justify-content-center alertpop bg-white p-5 rounded-3 shadow mx-3"
    :class="type && device == 'mobile' ? 'alert-mobile' : ''"
    :style="style"
    v-if="blnLoading == true"
  >
    <div
      class="d-flex flex-column gap-3 justify-content-center"
      style="width: 700px; height: 100%"
    >
      <div class="skeleton rounded" style="width: 100%; height: 30px">
        <div class="skeleton-item rounded mb-3"></div>
      </div>
      <div class="skeleton rounded" style="width: 100%; height: 30px">
        <div class="skeleton-item rounded mb-3"></div>
      </div>
      <div class="skeleton rounded" style="width: 100%; height: 30px">
        <div class="skeleton-item rounded mb-3"></div>
      </div>
      <div class="d-flex gap-2">
        <div class="" style="width: 60%; height: 30px"></div>
        <div class="skeleton rounded" style="width: 20%; height: 30px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
        <div class="skeleton rounded" style="width: 20%; height: 30px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Radio from "@/components/input/radio.vue";
import InputPassword from "@/components/input/inputPassword.vue";
import { DeleteFill, WarningOutline } from "@/components/icon/index";
import Button from "@/components/button/button.vue";
export default {
  data() {
    return {
      overlay: true,
      password: null,
      blnShowMSG: false,
      // blnLoading: true,
    };
  },
  components: {
    DeleteFill,
    Button,
    WarningOutline,
    InputPassword,
    Radio,
  },
  props: {
    Cancel: { type: Boolean, default: true },
    blnError: { type: Boolean, default: false },
    sub: { type: String, default: "" },
    errorMsg: { type: String, default: "" },
    artName: { type: String, default: "" },
    idName: { type: String, default: "" },
    artIcon: { type: String, default: "" },
    altMsgBox: { type: String, default: "" },
    classCancelBtn: { type: String, default: "" },
    classOkBtn: { type: String, default: "" },
    classIcon: { type: String, default: "" },
    strButtonSubmit: { type: String, default: "" },
    strButtonCancel: { type: String, default: "" },
    type: { type: String, default: "" },
    Helper: { type: String, default: "" },
    showpass: { type: Boolean, default: false },
    btnpop: { type: Boolean, default: false },
    classAlertbox: { type: String, default: "" },
    acorgSub: { type: Boolean, default: false },
    subtitleLine1: { type: String, default: "" },
    subtitleLine2: { type: String, default: "" },
    style: { type: String, default: "" },
    blnLoading: { type: Boolean, default: false },
  },
  emits: ["confirm", "onRadio"],
  mounted() {
    this.password = null;
  },

  methods: {
    checkedRadio(id) {
      console.log(id);
    },
    deleted() {
      // console.log(this.password);
      this.clearRadio();
      this.$emit("confirm", this.password);
    },
    yesquit() {
      this.password = null;
      this.$emit("confirm", true);
    },
    cancel() {
      // console.log("confirm");
      this.password = null;
      this.clearRadio();
      this.$emit("confirm", false);
    },
    selectDataType(state) {
      this.$emit("onRadio", state);
    },
    clearRadio() {
      if (this.$refs.radio1 != null || this.$refs.radio2 != null) {
        document.getElementById("radio1").checked = false;
        document.getElementById("radio2").checked = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alertpop {
  width: fit-content !important;
  z-index: 2;
  // position: fixed;
  // top: 70px;
  // left: 50%;
  // transform: translateX(-50%);
  // z-index: 9999;
}
.alert-mobile {
  width: 90% !important;
}
.danger {
  .iconalert {
    fill: $danger !important;
  }
}
.warning {
  .iconalert {
    fill: $warning !important;
  }
}
.success {
  .iconalert {
    fill: $success !important;
  }
}
.overlaying {
  background-color: rgba(0, 0, 0, 0.404);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}
</style>
