<template>
  <div
    class="
      alert-msg
      d-flex
      align-items-center
      justify-content-between
      px-3
      py-2
    "
    v-show="blnalert"
    :style="
      device == 'mobile' ? 'width:90% !important;top:89% !important;' : ''
    "
  >
    <div>
      <div class="alert-right d-flex align-items-center">
        <component
          :is="icon"
          width="24"
          height="24"
          :class="coloricon"
          class="me-3"
        ></component>
        <span
          class="fnt-medium fnt-body"
          :class="device == 'mobile' ? 'fnt-caption' : ''"
          >{{ name }}</span
        >
      </div>
      <p
        v-if="showsub == true"
        class="fnt-regular fnt-body ms-6"
        :class="device == 'mobile' ? 'fnt-caption' : ''"
      >
        {{ subname }}
      </p>
    </div>
  </div>
</template>

<script>
// import { Icon } from "@iconify/vue";
import { CorrectFill, IncorrectFill } from "@/components/icon/index";
export default {
  props: {
    showsub: { type: Boolean, default: false },
    blnalert: { type: Boolean },
    name: { type: String },
    subname: { type: String },
    icon: { type: String },
    coloricon: { type: String },
  },
  emits: "Input",
  components: {
    // Icon,
    CorrectFill,
    IncorrectFill,
  },
  // watch: {
  //   msg() {
  //     this.clsArt();
  //   },
  // },
  methods: {
    clsArt() {
      this.alert = !this.alert;
    },
    // clsArt() {
    //   this.$emit("input");
    // },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  border: solid 1px $warning-200 !important;
  background-color: $warning-50 !important;
}
.success {
  border: solid 1px $success-200 !important;
  background-color: $success-50 !important;
}
.error {
  border: solid 1px $danger-200 !important;
  background-color: $danger-50 !important;
}
.alert-msg {
  position: fixed;
  width: 580px;
  border-radius: 4px;
  z-index: 9999;
  left: 50%;
  transform: translateX(-50%) !important;
  opacity: 0;
  visibility: hidden;
  transition: 150ms;
  top: 8%;
  .alert-right {
    font-size: 14px;
    svg {
      margin-right: 12px;
      color: $success;
    }
  }
}
.show {
  opacity: 1;
  visibility: visible;
  transition: 150ms;
  top: 11%;
}
</style>