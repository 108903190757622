
// import axios from "axios";
// import urlapi from "@/typescript/urlapi/url";

import cookiesPath from "./urlapi/cookiesPath";

const Login = {

    login: async (username: any, password: any, axios: any, urlapi: any) => {

        let intWrongLogin = 0;
        intWrongLogin = Number(localStorage.getItem("intWrongLogin"));
        let newLocal = {};
        try {
            const _logindata = {
                username: username?.split('@')[0],
                password: password,
            };

            console.log(_logindata);
            const res = await axios.post(
                urlapi + "/v1/api/Login/UserLoginConfirm",
                _logindata,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST",
                        "Access-Control-Allow-Headers": "Content-Type",
                    },
                }


            );
            console.log(res.data);
            if (res.data.logined == true) {

                newLocal = { logined: true, pincode: res.data.pincode, tel: res.data.tel, WrongLogin: intWrongLogin };

            } else {

                intWrongLogin += 1;
                localStorage.setItem("intWrongLogin", intWrongLogin.toString());
                newLocal = { logined: false, pincode: res.data.pincode, tel: res.data.tel, WrongLogin: intWrongLogin };

            }
        } catch (error) {

            intWrongLogin += 1;
            localStorage.setItem("intWrongLogin", intWrongLogin.toString());
            newLocal = { logined: false, pincode: "", tel: "", WrongLogin: intWrongLogin };

        }
        // console.log(newLocal)
        return newLocal;

    },
    logout: () => {

        localStorage.removeItem("intWrongLogin");
        //บันทึกชื่อผู้ใช้งานไปยัง store
        localStorage.removeItem("UserInfo");
        localStorage.clear();
        document.cookie = 'UserInfo' + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = 'https://cessystem.wu.ac.th/landing'
    },
}
export default Login;