<template>
  <div class="">
    <label class="txt-login d-flex align-items-center" :class="size">
      <Icon
        icon="ei:lock"
        class="icon"
        width="24"
        height="24"
        :class="classIcon"
      />
      <OpenEyeOutline
        v-if="blnshowEye && value != ''"
        class="icon-open-eye"
        width="24"
        height="24"
        style="stroke: #1f1f1f"
        @click="showPassword()"
      />
      <HideEyeOutline
        v-if="!blnshowEye && value != ''"
        class="icon-open-eye"
        width="24"
        height="24"
        @click="showPassword()"
      />
      <input
        :type="typeInp"
        required
        :class="classInput"
        :name="name"
        :id="name"
        :value="value"
        readonly
        onfocus="this.removeAttribute('readOnly');"
      />
      <p
        class="label-text"
        :class="
          (value != '' && value != undefined && value != null ? 'move ' : ' ') +
          text
        "
      >
        {{ label }}<span>{{ labelSub }}</span>
      </p>
    </label>
    <p
      v-if="blnShowMSG == true"
      :class="device == 'mobile' ? 'fnt-caption' : ''"
    >
      {{ helper }}
    </p>
    <div
      v-if="blnError == true"
      :class="device == 'mobile' ? 'fnt-caption' : ''"
      class="text-danger"
    >
      <InfoOutline
        width="16"
        height="16"
        class="me-1 fill-danger"
        :class="blnError == true ? '' : 'd-none'"
      />
      {{ $t(error) }}
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import {
  InfoOutline,
  OpenEyeOutline,
  HideEyeOutline,
} from "@/components/icon/index";
import { Icon } from "@iconify/vue";
export default {
  data() {
    return {
      alertMsgStatus: false,
      alertMsgInput: "",
      blnshowEye: true,
      typeInp: "password",
    };
  },

  props: {
    blnError: { type: Boolean, default: false },
    name: { type: String, default: "name" },
    label: { type: String },
    value: { type: String },
    statename: { type: String },
    maxlength: { type: String },
    validate: { type: String },
    labelSub: { type: String }, // เป็น Label เสริมเมื่อทำการ Select จะหายไป
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
    classIcon: { type: String, default: "" },
    text: { type: String, default: "" },
    size: { type: String, default: "" },
    helper: { type: String, default: "" },
    classInput: { type: String, default: "" },
    blnShowMSG: { type: Boolean, default: false },
    error: { type: String, default: "" },
  },
  components: {
    Icon,
    InfoOutline,
    OpenEyeOutline,
    HideEyeOutline,
  },
  methods: {
    showPassword() {
      let hidepassword = document.querySelector("#" + this.name);
      hidepassword.classList.toggle("fa-eye-slash");
      // const type =
      //   hidepassword.getAttribute("type") == "password" ? "text" : "password";
      // hidepassword.setAttribute("type", type);
      // console.log(hidepassword.getAttribute("type"))
      this.blnshowEye = !this.blnshowEye;

      if (this.blnshowEye == true) {
        this.typeInp = "password";
      } else {
        this.typeInp = "text";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.move {
  color: $primary !important;
  transition: all 0.2s !important;
  background-color: $white !important;
  font-size: 14px !important;
  top: -6% !important;
  left: 8px !important;
}
.small {
  height: 34px !important;
}
.txt-login {
  height: 48px;
  position: relative;
  width: 100%;
  border: solid 1px $black-400;
  border-radius: 4px;

  .icon {
    position: absolute;
    left: 4px;
  }
  .icon-open-eye {
    position: absolute;
    right: 4px;
    &:hover {
      cursor: pointer;
    }
  }

  p {
    position: absolute;
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    padding: 0 4px;
    font-size: 16px;
    background: none;
    color: $black-500;
    cursor: text;
  }
  .pass-pop {
    left: 16px !important;
  }
  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }
  input {
    height: 100%;
    border: none;
    outline: 0;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    overflow: hidden;
    padding-left: 32px;

    &:focus {
      border: solid 1px $primary !important;

      & + p {
        top: -6%;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 14px;
        left: 8px;

        span {
          display: none !important;
        }
      }
    }

    &:valid + p {
      color: $primary;
      transition: all 0.2s;
      background-color: #fff;
      font-size: 14px;
      top: -6%;
      left: 8px;

      span {
        display: none !important;
      }
    }

    &:valid {
      border: solid 1px $primary !important;
    }
  }
}
</style>
